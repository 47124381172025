.ant-modal-root {
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.75) !important;
        z-index: 10000!important;
    }

    .ant-modal-wrap {
        z-index: 10000!important;
    }
}

.ant-modal-header {
    background: transparent !important;
    border-bottom: 1px solid rgba($color-white, 0.075);
    padding: 8px 16px;

    @media (min-width: $screen-md) {
        padding: 12px 24px;
    }
}

.ant-modal-body {
    padding: 8px 16px;

    @media (min-width: $screen-md) {
        padding: 12px 24px;
    }
}

.ant-modal-content {
    // background-image: $gradient-lighter;
    background-color: #202020 !important;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.1);
    // -webkit-backdrop-filter: blur(20px);
    // backdrop-filter: blur(20px);
    border-radius: $border-radius-card;
    // padding: 0 !important;

    .ant-modal-close {
        top: 14px;
        inset-inline-end: 14px;
    }
}

.ant-modal-deposit {
    @media (min-width: $screen-md) {
        width: 440px;
        max-width: 440px;
    }

    @media (max-width: $screen-md) {
        top: initial !important;
        bottom: 16px !important;
        vertical-align: bottom !important;
    }

    .ant-modal-footer {
        padding-bottom: 12px;
        border-top: 0;
    }

    .ant-modal-deposit-label {
        display: flex;
        margin-bottom: 6px;
    }

    .ant-modal-deposit-link {
        margin-left: auto;
        color: $color-primary;
        cursor: pointer;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.75;
        }
    }
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    &.no-margin {
        margin-left: 0 !important;
    }
}


.ant-modal-verify-phone {
    @media (min-width: $screen-md) {
        width: 450px;
        max-width: 450px;
    }

    .ant-modal-footer {
        padding-bottom: 12px;
        border-top: 0;
    }

    .ant-modal-verify-phone-label {
        display: flex;
        margin-bottom: 6px;
    }

    .ant-modal-verify-phone-link {
        margin-left: auto;
        color: $color-primary;
        cursor: pointer;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.75;
        }
    }

    .ant-alert-message{
        color: #202020!important;
    }
}

.ant-popup {
    .ant-modal-content {
        background: transparent !important;
        backdrop-filter: none;
        box-shadow: none;
        padding: 4px;

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-close {
            top: -40px;
            right: 0;
            width: 32px;
            height: 32px;
        }
    }

    .carousel-slider {
        overflow: visible;

        .control-dots {
            bottom: -35px;

            .dot {
                box-shadow: none;
            }
        }
    }

    .ant-pop-checkbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;

        .ant-checkbox .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }

        .ant-checkbox+span {
            font-size: 22px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            transform: rotate(45deg) scale(0.9) translate(-80%, -60%);
        }
    }
}

.home-modal-promotions {
    .ant-modal-body {
        max-height: 80dvh;
        overflow: auto;

        @media (min-width: $screen-md) {
            max-height: 90dvh;
        }
    }
}

.ant-image-preview-root .ant-image-preview-mask {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.ant-image-preview-close {
    top: 12px;
    right: 12px;
}

.ant-modal-iframe {
    width: auto !important;

    .ant-modal-content {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;

        .ant-modal-body {
            height: calc(100vh - 110px);

            iframe {
                height: 100vh;
                width: 100vw;
            }
        }
    }
}


.ant-modal-transactions {
    @media (min-width: $screen-md) {
        width: 440px;
        max-width: 440px;
    }
    .ant-modal-body{
        overflow-x: auto;
        max-height: 80dvh;
    }
}


.ant-modal-components {
    @media (min-width: $screen-md) {
        width: 440px;
        max-width: 440px;
    }
}
