body {
    position: relative;
    font-weight: normal;
    font-size: 24px;
    font-family: DB HelvethaicaX, Arial, sans-serif;
    padding: 0;
    margin: 0;
}

html,
body,
#root {
    height: 100%;
}

@media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
        display: none;
    }
}

.hidden {
    display: none !important;
}

.avatar-logo {
    background: transparent;

    img {
        object-fit: contain;
    }

    .ant-avatar-string {
        color: $color-primary;
        font-size: 40px;
        font-weight: bold;
    }
}

.ambpay-qrcode {
    text-align: center;
    margin-bottom: 16px;

    img {
        width: 150px;
        border: 8px solid #eee;
        border-radius: 8px;
    }
}

.heading {
    color: $color-primary;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}

.primary-color {
    color: $color-white;
}

.test-slider {
    position: relative;
    width: 100%;
    // min-height: 400px;
    display: flex;
    align-items: center;

    img {
        border-radius: 16px;
        animation: fadeIn 0.3s ease-in-out;
    }
}

.bg-main {
    position: relative;

    img {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        will-change: transform;
        transform: translateZ(0);
        overflow: hidden;
        z-index: -1;
        object-fit: cover;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    padding: 16px;
    height: 100dvh;
    box-sizing: border-box;
    width: 280px;
    display: none;

    .sidebar-inner {
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        // backdrop-filter: blur(50px);
        // -webkit-backdrop-filter: blur(50px);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
        border-radius: 16px;
        overflow: hidden;
    }

    @media (min-width: $screen-lg) {
        display: block;
    }
}

.content {
    width: auto;
    padding: 76px 12px 120px;
    box-sizing: border-box;

    @media (min-width: $screen-lg) {
        padding: 0 0 16px 280px;
    }
}

.chat-button {
    position: absolute;
    top: 0;
    right: 0;

    .ant-btn-dark {
        font-size: 20px;
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00a344;
        box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        border-radius: 0 32px 0 12px;

        i {
            padding-right: 10px;
            color: #fff !important;
        }

        span {
            margin-top: 3px
        }

        &:hover {
            transform: none;
            background: #00903c;
            box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        }
    }
}

.skeleton-container {
    position: relative;
    width: 100%;
    padding-top: 35.09%;
    background: linear-gradient(90deg, #373737 25%, #343434 50%, #373737 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.skeleton-popup-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #373737 25%, #343434 50%, #373737 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 16px;
}

.uid-line {
    padding-top: 6px;
    margin-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.075);
}

img {
    -webkit-touch-callout: none !important;
}

.button-footer-container {
    .ant-float-btn,
    .ant-float-btn-group {
        inset-inline-end: 12px;
        transition: all 0.25s ease;
        z-index: 9999;

        img {
            display: block;
            margin: 0 auto;
            width: 30px;
        }
    }

    .ant-float-btn-group {
        .ant-float-btn-group-wrap {
            margin-bottom: 8px;
        }
    }

    .ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) {
        margin-bottom: 8px;
    }

    @media (max-width: $screen-md) {
        .ant-float-btn-group {
            width: 48px;
            min-height: 48px;
            inset-block-end: 136px;
        }

        .ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) {
            .ant-float-btn-body {
                width: 48px;
                height: 48px;
            }
        }

        .ant-float-btn-circle {
            width: 48px;
            height: 48px;

            img {
                width: 24px;
            }

            .ant-float-btn-icon {
                font-size: 22px !important;
            }
        }
    }
}

.Toastify__toast-container,
.ant-image-preview-mask,
.ant-image-preview-wrap,
.ant-image-preview-operations-wrapper,
.ant-message {
    z-index: 99999 !important;
}

.ant-message {
    z-index: 10010 !important;
}

// .ant-typography,
// h4.ant-typography,
// h5.ant-typography,
// .ant-tag,
// .anticon-close,
// .anticon-search,
// .ant-form-item .ant-form-item-label>label,
// .ant-checkbox-wrapper,
// .ant-input,
// .ant-select,
// .ant-select-selection-placeholder,
// .ant-select-item-option-content,
// .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
// .ant-select-single .ant-select-selector,
// .ant-modal .ant-modal-title,
// .ant-input-number .ant-input-number-input,
// .ant-input-number-affix-wrapper,
// .ant-statistic .ant-statistic-content,
// .ant-radio-button-wrapper,
// .ant-radio-wrapper,
// .ant-empty .ant-empty-description,
// .ant-btn-default {
//     color: rgba(255, 255, 255, 0.98);
// }

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.1);
}

.ant-input:focus,
.ant-input:focus-within,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus-within,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus-within {
    box-shadow: none;
}