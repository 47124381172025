.form-card {
    position: relative;
    // perspective: 500px;

    @media (min-width: $screen-lg) {
        padding: 24px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        background: $gradient-primary;
        border-radius: 32px;
        z-index: 0;
        // transform-style: preserve-3d;
        will-change: transform;
        animation: rotateFormCardSm 10s infinite alternate;

        @media (min-width: $screen-lg) {
            top: 32px;
            left: 32px;
            right: 32px;
            bottom: 32px;
            animation: rotateFormCardLg 10s infinite alternate;
        }
    }

    .form-card-body {
        position: relative;
        padding: 24px 16px;
        background: rgba($color-black, 0.4);
        box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
        border-radius: 32px;
        backdrop-filter: blur(40px);
        z-index: 10;

        @media (min-width: $screen-lg) {
            padding: 24px;
        }
    }

    .ant-statistic {
        .ant-statistic-content {
            font-weight: bold;
            line-height: 0.8;
        }

        .ant-statistic-content-prefix {
            color: var(--color-link);
            font-size: 32px !important;
            margin-right: 8px;

            @media (min-width: $screen-lg) {
                font-size: 36px !important;
            }
        }

        .ant-statistic-content-value,
        .ant-statistic-content-value-int,
        .ant-statistic-content-value-decimal {
            font-size: 24px !important;

            @media (min-width: $screen-lg) {
                font-size: 36px !important;
            }
        }
    }
}

.margin-form-card {
    @media (min-width: $screen-lg) {
        margin-top: 5vw;
    }
}

.white-card {
    background: $gradient-light;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;
    border: 0;
}