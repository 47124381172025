.ant-radio-promotion {
    .ant-radio-wrapper {
        height: 52px;
        padding: 11.2px 16px;
        white-space: nowrap;
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
        background: $gradient-light;
        border-radius: 16px;
        transition: all 0.25s ease;

        .ant-radio {
            .ant-radio-inner {
                width: 20px;
                height: 20px;
                background: rgba($color-white, 0.9);
                border: 0;
                transition: background 0.25s ease;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 9px;
                    height: 5px;
                    border-bottom: 2px solid $color-white;
                    border-left: 2px solid $color-white;
                    transform: translate(-50%,-75%) rotate(-45deg);
                    opacity: 0;
                    transition: all .25s ease-out;
                    content: "";
                }

                &::after {
                    display: none;
                }
            }

            .ant-radio-input:focus+.ant-radio-inner {
                box-shadow: 0 0 0 3px rgba($color-success, 0.2);
            }
        }

        .ant-radio-checked {
            .ant-radio-inner {
                position: relative;
                background: $color-success;

                &::before {
                    opacity: 1;
                }
            }
        }

        &:hover {
            border-color: $color-line;

            .ant-radio-inner {
                background: $color-success;

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}