.ant-alert-info {
    background-color: $color-secondary;
    border-color: var(--color-secondary-h);
    padding: 10px 12px;
    margin-top: auto;

    .ant-alert-content{
        margin-left: 5px;
        .ant-alert-message{
            font-size: 24px;
        }
        .ant-alert-description{
            font-size: 20px;
        }
    }

    .ant-avatar{
        margin: 0!important;
    }
}