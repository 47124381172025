.bank-card {
    display: flex;
    padding: 12px;
    background: $gradient-light;
    border-radius: $border-radius-card;
    box-shadow: rgba(0, 0, 0, 0.25) 0 40px 80px, rgba(255, 255, 255, 0.15) 0 0 0 0.5px inset;

    .bank-card-logo {
        margin-bottom: 0;
        margin-right: 16px;
    }

    @media (min-width: $screen-lg) {
        padding: 16px;
    }
}

.bank-deposit-card {
    display: flex;
    padding: 12px;
    background: $gradient-light;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.075);
    border-radius: $border-radius-card;
    overflow: hidden;

    @media (min-width: $screen-lg) {
        padding: 24px;
    }
}

.bank-card-logo {
    position: relative;
    display: flex;
    border-radius: 16px;
    margin-right: 12px;
    width: 80px;
    height: 80px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba($color-black, 0) 20%, rgba($color-black, 1) 100%);
        box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 0.5px inset;
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(315deg, rgba($color-white, 0) 20%, rgba($color-white, 0.5) 100%);
        border-radius: 16px;
        z-index: 2;
        mix-blend-mode: soft-light;
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.bank-card-info {
    line-height: 1;

    small {
        color: rgba($color-white, 0.7);
    }

    button {
        padding: 0;
        margin-top: 16px;
        font-size: 20px;
        height: auto;
    }

    .bank-deposite-account {
        color: $color-white;
        font-size: 32px;
        font-weight: bold;
    }
}