.slot-card {
    position: relative;
    display: block;
    color: $color-white;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgba(#fff, 0.075);
    box-shadow: rgba($color-black, 1) 0 12px 32px -16px;
    will-change: border-color, box-shadow, transform;
    transition: all 250ms ease;
    overflow: hidden;
    cursor: pointer;

    @media (min-width: $screen-md) {
        font-size: 20px;
    }

    &:hover {
        border-color: $color-primary;
        box-shadow: hsla(var(--color-primary-hsl), 0.5) 0 12px 32px -16px;
        transform: scale(1.025);
    }
}

.slot-card-img {
    display: block;
    width: 100%;
}

.slot-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#101010, 0.7);
    // backdrop-filter: blur(4px);
    padding: 4px 4px 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $screen-md) {
        padding: 6px 4px 3px;
    }
}

.game-card {
    position: relative;
    display: flex;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(#fff, 0.075);
    cursor: pointer;

    .game-card-img-container {
        position: relative;
        width: 100%;
        padding-bottom: 137%;
        background-color: rgba(#202020, 0.7);
        will-change: transform;
        transform: translateZ(0);
    }

    .game-card-logo {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
        object-fit: cover;
    }

    .game-card-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .game-card-name {
        position: absolute;
        bottom: 0;
        left: -1px;
        right: -1px;
        background-color: rgba(#101010, 0.7);
        // backdrop-filter: blur(4px);
        padding: 2px 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-white;
        font-size: 20px;
        text-align: center;
        line-height: 1;
        will-change: transform;
        transform: translateZ(0);

        @media (min-width: $screen-md) {
            padding: 2px 4px 1px;
        }
    }
}