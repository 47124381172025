.deposit-method {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 24px 16px 24px 96px;
    color: var(--color-text-primary-bg);
    background: $gradient-primary;
    border-radius: $border-radius-card;
    box-shadow: inset 0 0 0 1px rgba($color-white, 0.2), hsla(var(--color-primary-hsl), 0) 0 25px 20px -20px;
    ;
    transition: all 0.25s ease;
    cursor: pointer;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: -30px;
        width: 96px;
        height: 96px;
        background: rgba(0, 0, 0, 0.25);
        filter: blur(20px);
    }

    &.bg-secondary {
        background: $gradient-dark;
        border: 1px solid rgba($color-white, 0.05);

        .deposit-method-name {
            color: $color-white;

            small {
                color: rgba($color-white, 0.5);
            }
        }

        .anticon-right {
            color: $color-white;
        }

        &:hover {
            box-shadow: rgba($color-black, 0.5) 0 25px 20px -20px;
        }
    }

    .deposit-method-icon {
        position: absolute;
        bottom: -12px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        margin-right: 16px;
        transition: all 0.25s ease;

        img {
            // width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .deposit-method-name {
        font-size: 28px;
        line-height: 0.8;
        transition: all 0.25s ease;

        @media (min-width: $screen-lg) {
            font-size: 32px;
        }

        small {
            color: var(--color-text-primary-bg);
            opacity: 0.75;

            @media (min-width: $screen-lg) {
                font-size: 22px;
            }
        }
    }

    .anticon-right {
        margin-left: auto;
    }

    &:hover {
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.2), hsla(var(--color-primary-hsl), 1) 0 25px 20px -20px;
        ;
        transform: scale(1.015);

        .deposit-method-icon {
            transform: scale(1.15);
        }

        .deposit-method-name {
            transform: translateX(12px);
        }
    }
}

.truewallet-qr-deposit {
    margin-top: 16px;
    text-align: center;

    img {
        width: 150px;
        border: 1px solid rgba($color-white, 0.2);
        border-radius: 0.25rem;
    }

    h5 {
        margin: 0.5rem 0 0 0;
        font-size: 1.2rem;
        color: $color-primary;
    }
}

.promotion-slider-container {
    overflow-x: auto;
    display: flex;
    margin: 0 -12px 16px;
    padding: 0 12px;

    @media (min-width: $screen-lg) {
        margin: 0 0 16px;
        padding: 0;

        &.center {
            justify-content: center;
        }
    }

    .promotion-slider-item {
        position: relative;
        font-size: 18px;
        color: $color-white;
        min-width: 110px;
        width: 110px;
        background: $gradient-dark;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-shadow: 0 2px 4px rgba($color-black, 0.25);
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.1);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 8px;
        }

        .promotion-slider-item-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        .promotion-slider-item-checked {
            position: absolute;
            top: 0;
            right: -4px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $color-success;

            .material-symbols-rounded {
                font-size: 20px;
            }
        }

        img {
            width: 20px;
            margin-bottom: 6px;
        }

        &.active {
            background: $gradient-secondary;
            box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);

            .promotion-slider-item-title {
                color: var(--color-text-secondary-bg);
            }
        }
    }
}