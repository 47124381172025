.footer {
    padding: 24px 16px;
    margin: 24px 0 0;
    background: rgba(0, 0, 0, 0.75);
    // background: rgba(0, 0, 0, 0.25);
    // backdrop-filter: blur(50px);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.075);
    border-radius: 12px;
    text-align: center;

    @media screen and (min-width: $screen-lg) {
        padding: 40px 24px;
    }

    .footer-info {
        h1 {
            font-size: 32px;
            color: $color-primary;
            margin: 0 0 8px;
            line-height: 1;
        }

        p {
            font-size: 22px;
            margin: 0 0 20px;
            color: rgba(255, 255, 255, 0.88)
        }
    }

    .footer-tags {
        a {
            .ant-tag {
                margin-bottom: 8px
            }

            &:hover {
                .ant-tag {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    .footer-partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 16px;

        img {
            width: 36px;
            margin: 4px;
            align-self: center;
            justify-self: center;

            @media screen and (min-width: $screen-lg) {
                width: 40px;
            }
        }
    }
}